import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout, renderModules } from '../../components';
import { HeroBackgroundImage } from '../../components/images';
import RichText from '../../components/rich-text';
import CtaButton from '../../components/cta-button';
import './index.scss';

export default function GenericPage ({ path, data }) {
  const {
    title, heroHeading, heroText, heroCta, heroCtaTarget, modules, metaDescription,
    heroBackgroundImage, ogImage
  } = data.contentfulGenericPage;

  return (
    <Layout currentPath={path}>
      <Helmet>
        <title>{title?.title}</title>
        <meta name="description" content={metaDescription?.metaDescription} />
        <meta name="og:title" content={title?.title} />
        <meta name="og:description" content={metaDescription?.metaDescription} />
        <meta name="og:image" content={ogImage?.fixed?.src} />
      </Helmet>

      <section className="hero">
        <HeroBackgroundImage heroBackgroundImage={heroBackgroundImage} />
        <div className="container hero__inner">
          <h3>{heroHeading?.heroHeading}</h3>
          <div className="hero-text"><RichText node={heroText} /></div>
          <CtaButton ctaText={heroCta} ctaTarget={heroCtaTarget} />
        </div>
      </section>

      {renderModules(modules)}
    </Layout>
  );
}

export const pageQuery = graphql`
query GenericPageById($id: String!) {
  contentfulGenericPage(id: {eq: $id}) {
    title { title }
    metaDescription { metaDescription }
    heroHeading { heroHeading }
    heroText { heroText }
    heroCtaText

    ogImage: heroBackgroundImage {
      ...pageOgImageFields
    }

    heroBackgroundImage {
      ...heroBackgroundImageFields
    }

    heroCtaTarget {
      __typename
      ... on ContentfulAboutPage { id }
      ... on ContentfulCareersPage { id }
      ... on ContentfulContactUsPage { id }
      ... on ContentfulEvent { id }
      ... on ContentfulGenericPage { id }
      ... on ContentfulNewsletterSignupPage { id }
      ... on ContentfulResource { id }
      ... on ContentfulResourcesPage { id }
      ... on ContentfulSolution { id }
      ... on ContentfulSolutionCategory { id }
    }

    modules {
      __typename
      ... on ContentfulArticleContentModule {
        ...articleContentModuleFields
      }
      ... on ContentfulBeyondZeroHarmModule {
        ...beyondZeroHarmModuleFields
      }
      ... on ContentfulContactModule {
        ...contactModuleFields
      }
      ... on ContentfulCtaArrowsModule {
        ...ctaArrowsModuleFields
      }
      ... on ContentfulDownloadModule {
        ...downloadModuleFields
      }
      ... on ContentfulFeatureModule {
        ...featureModuleFields
      }
      ... on ContentfulFeaturedResourcesModule {
        ...featuredResourcesModuleFields
      }
      ... on ContentfulFeaturedSolutionsModule {
        ...featuredSolutionsModuleFields
      }
      ... on ContentfulHorizontalLinksModule {
        ...horizontalLinksModuleFields
      }
      ... on ContentfulImageVideoModule {
        ...imageVideoModuleFields
      }
      ... on ContentfulImagesWithArrowModule {
        ...imagesWithArrowModuleFields
      }
      ... on ContentfulLeadershipModule {
        ...leadershipModuleFields
      }
      ... on ContentfulPrimaryContentModule {
        ...primaryContentModuleFields
      }
      ... on ContentfulQuoteModule {
        ...quoteModuleFields
      }
      ... on ContentfulSideBlockModule {
        ...sideBlockModuleFields
      }
      ... on ContentfulTwoColumnModule {
        ...twoColumnModuleFields
      }
    }
  }
}
`;
